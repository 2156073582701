<template>
  <!-- 病历列表(就诊中和待就诊页用) -->
  <div class="medical_record_list">
    <div class="title">病历</div>

    <Spin class="u_spin" v-if="isLoading" fix>
      <Icon type="ios-loading" size="30" class="demo-spin-icon-load"></Icon>
      <div>加载中...</div>
    </Spin>

    <div class="medical_record_list_body" :class="{ isPrescriptionPage }">
      <Collapse v-model="collapseValue">
        <Panel :name="index + ''" v-for="(item, index) in list" :key="index">
          <div class="header">
            <span class="diseaseTypeName">
              {{ item.diseaseTypeName }}
            </span>
            <span class="lc text-green">{{ progressStatusStr(item) }}</span>
            <span class="time">{{ item.visitDate }}</span>
          </div>
          <template slot="content">
            <div class="m_r_l_item" :class="{ first_item: !index }">
              <div v-if="isPrescriptionPage" class="fiexed_right">
                <div class="title_tip">(本次病历)</div>
                <Dropdown
                  v-if="item.diagnosticDrugsVOList"
                  placement="bottom-end"
                  @on-click="clickItem(item, $event)"
                >
                  <a href="javascript:void(0)">
                    <Icon type="md-more" />
                  </a>
                  <DropdownMenu slot="list">
                    <DropdownItem name="quote">引用处方</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div class="item">
                <div class="label">主诉</div>
                <div class="value">
                  {{ item.purposeOfConsultationName }}
                  <span class="other">{{ chiefComplaint(item) }}</span>
                </div>
              </div>
              <div class="item">
                <div class="label">诊断</div>
                <div class="value">
                  {{ item.diseaseTypeName }}
                  <span class="other">{{
                    Disease_Type(item.diseaseTypeId)
                  }}</span>
                  <span class="cureNumber" v-if="item.cureNumber"
                    >治愈:{{ item.cureNumber }}人</span
                  >
                </div>
              </div>
              <div
                class="item"
                v-if="info.sex == '女' && item.menstruationLastTime"
              >
                <div class="label">末次月经</div>
                <div class="value">{{ item.menstruationLastTime }}</div>
              </div>
              <div class="item">
                <div class="label">症状</div>
              </div>
              <div class="item">
                <div class="value">
                  <!-- <Badge
                    v-for="ds in item.dsVOList"
                    :key="ds.dsId"
                    :text="ds.isAddDs ? '增' : ''"
                  >
                    <span class="symptom_name">{{ ds.dsName }}</span>
                  </Badge> -->
                  <symptomChanges
                    v-for="ds in item.dsVOList"
                    :key="ds.dsId"
                    :symptomInfo="ds"
                  >
                    <span class="symptom_name">{{ ds.dsName }}</span>
                  </symptomChanges>
                  
                  <i
                    class="line"
                    v-if="item.removeDsVOList && item.removeDsVOList.length"
                  ></i>
                  <Badge
                    v-for="ds in item.removeDsVOList"
                    :key="ds.dsId"
                    class="symptom_remove"
                    text="减"
                  >
                    <span class="symptom_name">{{ ds.dsName }}</span>
                  </Badge>
                </div>
              </div>

              <div class="item" v-if="item.chiefComplaint">
                <div class="label">患者自述</div>
                <div class="value">{{ item.chiefComplaint }}</div>
              </div>
              <template v-if="item.diagnosticDrugsVOList">
                <div class="item">
                  <div class="label">处方</div>
                </div>
                <div class="item cf">
                  <div class="value">
                    <drugs-format :list="item.diagnosticDrugsVOList" />
                  </div>
                  <div class="sl" v-if="item.diagnosticDrugsVOList.length">
                    x{{ item.diagnosticDrugsVOList[0].quantity }}
                  </div>
                </div>
              </template>
              <div v-uEdition="'YZ-11'">
                <template v-if="item.treatmentProgressList.length">
                  <div class="item">
                    <div class="label">疗程进度</div>
                  </div>
                  <div class="item">
                    <treatmentProgress :list="item.treatmentProgressList" />
                  </div>
                </template>
              </div>
              <div class="foot">
                {{ item.visitDate }} {{ item.hospitalName }}
                {{ item.doctorName }}
              </div>
            </div>
          </template>
        </Panel>
      </Collapse>

      <empty v-if="!list.length" />
    </div>

    <div v-if="showBtn" @click="nextPage" class="more">
      显示更多病历
      <Icon type="ios-arrow-down" />
    </div>
  </div>
</template>

<script>
import treatmentProgress from "./treatmentProgress.vue";
import empty from "./empty.vue";
import { mapGetters } from "vuex";
export default {
  components: { empty, treatmentProgress },
  props: {
    // 患者ID
    patientCode: {
      type: String,
      default: "",
    },
    // 症状编号
    symptomNo: {
      type: String,
      default: "",
    },
    // 是否处方页面
    isPrescriptionPage: {
      type: Boolean,
      default: false,
    },
    // 患者信息
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters([
      "dicts",
      "chiefComplaintTimeTypeList",
      "CHIEFCOMPLAINT_DEGREE", // 主诉程度
      "progressStatus",
      "treatmentProgressList",
    ]),
  },
  watch: {
    patientCode(newValue, oldValue) {
      this.init();
    },
    treatmentProgressList(value) {
      if (this.list.length) {
        this.list[0].treatmentProgressList = value;
      }
    },
  },
  data() {
    return {
      collapseValue: "0",
      isLoading: false,
      total: 0,
      list: [],
      pageSize: 2,
      visitDate: "",
      showBtn: true,
      pageNo: 1, //页码
    };
  },
  methods: {
    progressStatusStr(item) {
      let list = item.treatmentProgressList;
      return list.length ? this.progressStatus[list[0].progressStatus] : "";
    },
    clickItem(item, name) {
      switch (name) {
        case "quote":
          this.$emit("quote", item.diagnosticDrugsVOList);
          break;
      }
    },
    chiefComplaint(item) {
      let str = "";
      if (item.chiefComplaintTime) {
        str = `持续: ${item.chiefComplaintTime}`;
        let i = this.chiefComplaintTimeTypeList.find(
          (i) => i.value == item.chiefComplaintTimeType
        );
        str += i ? i.label : "";
      }

      if (item.chiefComplaintDegree) {
        let i = this.CHIEFCOMPLAINT_DEGREE.find(
          (i) => i.value == item.chiefComplaintDegree
        );
        str += ` 程度: ${i.label}`;
      }
      return str;
    },
    init() {
      this.total = 0;
      this.list = [];
      this.pageSize = 2;
      this.pageNo = 1;
      this.visitDate = "";
      this.showBtn = true;
      this.isLoading = false;
      this.getData();
    },
    nextPage() {
      this.pageNo += 1;
      this.getData();
    },
    Disease_Type(diseaseTypeId) {
      let list = this.dicts["Disease_Type"] || [];
      let obj = list.find((i) => i.value == diseaseTypeId);
      if (obj) {
        return obj.string5
          ? `疗程：${
              obj.string5 +
              obj.string5Unit +
              "~" +
              obj.string6 +
              obj.string6Unit
            }`
          : "";
      } else {
        return "";
      }
    },

    //  判断是否有缓存数据 如果有就不调接口
    isOldData() {
      let state = false;
      if (this.pageNo == 1 && this.$route.name == "prescription") {
        let oldData = sessionStorage.getItem(
          "listPatientMainSymptom_submit_" + this.info.registerCode
        );
        if (oldData) {
          this.list = JSON.parse(oldData);
          if (this.list.length < 2) {
            this.showBtn = false;
          }
          state = true;
        }
      }
      return state;
    },
    getData() {
      if (this.isOldData()) return;

      let obj = {
        pageSize: this.pageSize,
        patientCode: this.patientCode,
        pageNo: this.pageNo,
        searchType: 1,
      };

      if (this.visitDate) obj.startTime = this.visitDate;
      if (this.symptomNo) obj.symptomNo = this.symptomNo;

      this.isLoading = true;
      this.api2("listPatientMainSymptom", obj)
        .then((res) => {
          let list = res.result;
          // 是同一个患者的数据才加入列表
          if (list.some((i) => i.patientCode == this.patientCode)) {
            if (list.length) {
              this.visitDate = list[list.length - 1].visitDate;
            } else {
              this.showBtn = false;
            }

            this.list = this.list.concat(list);
            if (this.list.length < 2) {
              this.showBtn = false;
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
          if (this.$route.name == "condition") {
            sessionStorage.setItem(
              "listPatientMainSymptom_" + this.info.registerCode,
              JSON.stringify(this.list)
            );
          }
        });
    },
  },

  created() {
    this.$store.dispatch("CHIEFCOMPLAINT_DEGREE");
    this.getData();
  },
};
</script>

<style lang="scss" >
.medical_record_list {
  background: #fff;
  border-radius: 5px 5px 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .medical_record_list_body {
    height: 100%;
    overflow-y: auto;
  }

  .ivu-spin-fix.u_spin {
    background-color: rgba(255, 255, 255, 0.8);

    .ivu-spin-main {
      background: transparent;
      box-shadow: none;
    }
  }

  .title {
    height: 36px;
    line-height: 36px;
    background: #853a2f;
    text-align: center;
    color: #fff;
    border-radius: 5px 5px 0 0;
    position: relative;
    z-index: 101;
  }

  .isPrescriptionPage {
    .title_tip {
      display: none;
      font-size: 12px;
      color: #b20000;
    }

    .first_item {
      padding-top: 22px;

      .title_tip {
        display: inline-block;
      }

      background: #75f9fd12;

      .ivu-dropdown {
        display: none;
      }
    }
  }

  .m_r_l_item {
    border-bottom: 1px solid #ddd;
    padding: 0;
    position: relative;

    &:last-child {
      border: none;
    }

    .fiexed_right {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .foot {
      text-align: right;
      font-size: 12px;
      color: #999;
    }

    .cf {
      position: relative;

      .value {
        padding-right: 30px;
      }

      .sl {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 22px;
        color: #f00;
        font-weight: bold;
      }
    }

    .item {
      line-height: 2em;

      & > div {
        display: inline-block;
      }

      .label {
        color: #999;
        width: 62px;
      }

      .other {
        font-size: 12px;
        background: #fffce0;
        display: inline-block;
        line-height: 16px;
        padding: 0 8px;
      }

      .cureNumber {
        font-size: 12px;
        background: #ffd1b1;
        display: inline-block;
        line-height: 16px;
        padding: 0 8px;
        border-radius: 3px;
      }
    }

    .ivu-badge {
      margin: 0 10px 13px 0;
      height: 30px;
    }

    .symptom_name {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding: 0 8px;
      border-radius: 3px;
      overflow: hidden;
      font-size: 14px;
      background: #f2e6d4;
    }

    .line {
      display: inline-block;
      height: 30px;
      margin: 0 15px 0 5px;
      border-left: 1px solid #ccc;
    }

    .symptom_remove {
      .ivu-badge-count,
      span {
        background: #eee;
      }

      .ivu-badge-count {
        color: #333;
      }
    }

    .ivu-badge-count {
      height: 16px;
      line-height: 14px;
      font-size: 12px;
      padding: 0 2px;
      right: 0px;
      top: -8px;
    }
  }

  .more {
    text-align: center;
    cursor: pointer;
    padding: 10px 0;
    border-top: 1px solid #e5e5e5;
  }

  .ivu-collapse-header {
    .header {
      display: flex;
      white-space: nowrap;

      .diseaseTypeName {
        flex: 1;
      }

      .lc,
      .time {
        font-size: 12px;
        padding-right: 10px;
      }
    }

    .ivu-icon {
      float: right;
      margin-top: 12px;
    }
  }

  .ivu-collapse-content,
  .ivu-collapse-content > .ivu-collapse-content-box {
    padding: 0;
  }
}
</style>
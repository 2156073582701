<template>
    <div class="patient-label">
        <Button v-if="!hideBtn" type="default" size="small" @click="showFun" icon="md-pricetags">标签选择</Button>
        <Modal class="patient-label-modal" width="600px" v-model="show" title="标签选择">
            <div class="modal-body">
                <Table :columns="columns" :data="data" @on-selection-change="change" @on-row-click="onOnRowClick">
                </Table>
            </div>
            <div slot="footer">
                <Button @click="show = false">取消</Button>
                <Button type="primary" @click="submit">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        hideBtn: {
            type: Boolean,
            default: false,
        },
        parentPage: {
            type: String,
            default: ''
        }
    },
    watch: {
        info(newValue, oldValue) {
            this.init();
        },
        show(value){
            this.init();
        }
    },
    computed: {
        ...mapGetters(["userInfo","saveCondition"]),
    },
    data() {
        return {
            show: false,
            courseCrowdId: "",
            list: [],
            ids: [],
            columns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '标签',
                    width: 170,
                    key: 'label'
                },
                {
                    title: '标签说明',
                    key: 'desc'
                },
            ],
            data: [
                {
                    label: "病程管理",
                    desc: "患者同意加入病程管理的标签。由医生或技术馆员标记，病程结束后由用导伙伴在后台系统取消标记 。",
                    id: 1,
                    _checked: false,
                    _disabled: false,
                },
                {
                    label: "病程管理第三类人群",
                    desc: "患者属于病程管理第三类人群的标签。由医生或技术馆员标记，或取消标记。",
                    id: 3,
                    _checked: false,
                    _disabled: false,
                },
                {
                    label: "安安适用人群",
                    desc: "患者已治愈或好转，可以停药，满足购买安安条件的标签。由医生或技术馆员标记，或取消标记。",
                    id: 4,
                    _checked: false,
                    _disabled: false,
                },
            ],
        }
    },
    methods: {
        onOnRowClick(data, index) {
            if (!data._disabled) {
                this.data[index]._checked = !this.data[index]._checked
                if (this.data[index]._checked) {
                    // 如果是安安 给出二次提示
                    if(data.id == 4){
                        if(this.info.patientAge > 70 ){
                            this.$Message.error("大于70岁的患者暂时不提供安安服务")
                            this.data[index]._checked = false;
                            return;
                        }
                        this.$Modal.confirm({
                            title: '提示',
                            content: '确认该患者满足购买安安的条件吗？',
                            onOk: () => {
                                this.ids.push(data.id)
                            },
                            onCancel: () => {
                                this.data[index]._checked = false;
                            }
                        })
                    }else{
                        this.ids.push(data.id)
                    }
                } else {
                    let index = this.ids.findIndex(i => i == data.id);
                    if (index != -1) {
                        this.ids.splice(index, 1);
                    }
                }
            }
        },
        change(res) {
            this.ids = res.map(i => i.id)
            this.data.forEach(i => {
                if (this.ids.includes(i.id)) {
                    i._checked = true;
                } else {
                    i._checked = false;
                }
            })
        },
        boxChange(list) {
            this.ids = list.length ? [list[list.length - 1]] : []
        },
        showFun() {
            this.getWideUseList();
        },
        init() {
            if(!this.show) return;
            let isCourseManage = this.info.isCourseManage ? true : false;
            this.data[0]._checked = isCourseManage
            // this.data[0]._disabled = isCourseManage
            this.data[1]._checked = this.info.courseCrowdName ? true : false;

            this.ids = []
            if (this.info.isCourseManage) this.ids.push(1)
            if (this.info.courseCrowdName) this.ids.push(3)

            // 暂时关闭
            this.getPatientAnanAuth();
        },
        submit() {
            this.savaDiseaseCourseManage();
            this.bindPatientAnanAuth();
            let courseCrowdId = this.ids.includes(3) ? 3 : ""; //病程人群分类id
            let d = this.info.courseCrowdName ? 3 : "";
            if (d == courseCrowdId) {
                return;
            }
            let obj = {
                courseCrowdId, //病程人群分类id
                "patientCode": this.info.patientCode, //患者编码
            }
            this.api2("updatePatientCourseCrowd", obj).then(res => {
                this.show = false;
                let label = "";
                if (courseCrowdId) {
                    let item = this.list.find(i => i.value == courseCrowdId);
                    if(item) label = item.label;
                }
                // this.info.courseCrowdName = label
                this.info.courseCrowdName =courseCrowdId ? 1 : 0; 
            });
        },
        bindPatientAnanAuth() {
            if(this.ids.includes(4)) {
                let diseaseCode = ''
                let diseaseInfo = this.saveCondition[this.info.registerCode]
                if(!this.info.diagnosticId2 && !diseaseInfo){
                    if(this.parentPage === ''){
                        this.$Message.warning('该患者未就诊过，无法购买安安');
                    }else{
                        this.$Message.warning('请先选择诊断');
                    }
                    return
                }
                diseaseCode = this.info.diagnosticId2 || diseaseInfo.info.diagnosis2
                let obj = {
                    patientCode: this.info.patientCode,
                    diseaseCode: diseaseCode,
                    age: this.info.patientAge,
                };
                this.api2('bindPatientAnanAuth', obj).then(res => {
                    this.$emit("anan");
                    this.addPatientAnanMessage();
                }).finally(() => {
                })
            }
        },
        addPatientAnanMessage(){
            let obj = {
                hospitalCode: this.userInfo.hosptialCode, //	医馆编码
                patientCode: this.info.patientCode,
                patientName: this.info.patientName, //	医馆编码
            }
            this.api2("addPatientAnanMessage", obj).then(res=>{})
        },
        savaDiseaseCourseManage() {
            let courseStatus = this.ids.includes(1) ? 1 : 0; // 表示取消加入
            if (this.info.isCourseManage == courseStatus) {
                this.show = false;
                return;
            }
            let obj = {
                diseaseId: this.info.diagnosticId2, //	病种编码	
                diseaseName: this.info.diagnosticName2, //	病种名称	
                patientCode: this.info.patientCode, //患者编码
                hospitalCode: this.info.hospitalCode, //	医馆编码	
                courseStatus
            }
            this.api2("savaDiseaseCourseManage", obj).then(res => {
                this.show = false;
                this.info.isCourseManage = obj.courseStatus
                // this.data[0]._disabled = true;
            })
        },
        getWideUseList() {
            this.$store.commit("openSpin");
            let obj = {
                "pageSize": 100,
                "pageIndex": 1,
                "targetUse": "THREE_GROUPS"
            };
            this.api2('getWideUseList', obj).then(res => {
                this.list = res.result.filter(i => i.numeric1).map(i => {
                    i.label = i.string1;
                    i.value = i.mainKey;
                    return i;
                })
                this.show = true;
            }).finally(() => {
                this.$store.commit("closeSpin");
            })
        },
        
        getPatientAnanAuth() {
            let item = this.data.find(i=>i.id == 4);
            let obj = {
                patientCode: this.info.patientCode
            };
            this.api2('getPatientAnanAuth', obj).then(res => {
                if(res.result && res.result.bindStatus == "1"){
                    item._checked = true;
                    item._disabled = true;
                }else{
                    item._checked = false;
                    item._disabled = false;
                }
            }).finally(() => {
            })
        },
    },
    created() {
        this.init();
    },

}
</script>

<style lang="scss">
.patient-label {
    display: inline-block;
    margin-left: 5px;

    .ivu-btn-small {
        height: 21px;
        line-height: 19px;
        font-size: 12px;
    }

}

.patient-label-modal .ivu-table-row {
    cursor: pointer;
}
</style>